import { setupClientAuth } from "@packages/queries-client-auth";
import { API_ACCELERATOR } from "@nutrien-operations/config";
export { API_ACCELERATOR } from "@nutrien-operations/config";

import { client } from "./generated";

export * from "./generated";
export * from "./generated/@tanstack/react-query.gen";
export * from "./custom";

client.setConfig({ baseUrl: API_ACCELERATOR });

setupClientAuth(client);
