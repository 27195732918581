import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, PersonaResponse } from "../../../types";
import { personasQueryKey } from "../query-cache";

export const useGetPersonaById = (personaId: string) => {
  const queryKey = personasQueryKey(personaId);

  const queryOptions: UseQueryOptions<PersonaResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.Personas.getById(personaId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    persona: data?.data,
    error
  };
};
