import { lazy, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { PageContainer, SpinningLoader, Helmet } from "@packages/theme-mui-v5";
import ApplicationAccessManagement, {
  ApplicationAccessPermissionsProvider
} from "@packages/application-access-management";

import config, {
  AcceleratorCRUDDefinitions,
  AlarmsCRUDPermissionIds,
  AssetsCRUDPermissionIds,
  basePath,
  TagMappingsCRUDPermissionIds,
  viewPermissions
} from "./config";
import { PermissionsProvider, WithPermissions } from "@packages/service-api";
import { CRUDPermissionIds } from "@nutrien-operations/config";

const Overview = SpinningLoader(lazy(() => import("./pages/overview/Overview")));
const CsvUpload = SpinningLoader(lazy(() => import("./drawers/csv-upload/CsvUpload")));

const AlarmModes = SpinningLoader(lazy(() => import("./pages/alarm-modes/AlarmModes")));

const AddEditAlarmMode = SpinningLoader(
  lazy(() => import("./drawers/alarm-modes/AddEditAlarmMode"))
);

const DeleteAlarmMode = SpinningLoader(lazy(() => import("./drawers/alarm-modes/DeleteAlarmMode")));
const AssetProperties = SpinningLoader(
  lazy(() => import("./pages/asset-properties/AssetProperties"))
);

const DeleteAssetProperty = SpinningLoader(
  lazy(() => import("./drawers/asset-properties/DeleteAssetProperty"))
);

const AssetTemplatesPage = SpinningLoader(
  lazy(() => import("./pages/asset-templates/AssetTemplatesPage"))
);

const AssetPersonaTemplates = SpinningLoader(
  lazy(() => import("./pages/asset-persona-templates/AssetPersonaTemplates"))
);

const DeleteAssetTemplateDrawer = SpinningLoader(
  lazy(() => import("./drawers/asset-templates/DeleteAssetTemplateDrawer"))
);

const CloneAssetTemplateDrawer = SpinningLoader(
  lazy(() => import("./drawers/asset-templates/CloneAssetTemplateDrawer"))
);

const DeleteAssetPersonaTemplateDrawer = SpinningLoader(
  lazy(() => import("./drawers/asset-persona-templates/DeleteAssetPersonaTemplateDrawer"))
);

const AssetNameplateTemplates = SpinningLoader(
  lazy(() => import("./pages/asset-nameplate-templates/AssetNameplateTemplates"))
);
const DeleteAssetNameplateTemplate = SpinningLoader(
  lazy(() => import("./drawers/asset-nameplate-templates/DeleteAssetNameplateTemplate"))
);

const AssetsAbnormalThresholds = SpinningLoader(
  lazy(() => import("./pages/assets/AssetsAbnormalThresholdsDataGrid"))
);
const Assets = SpinningLoader(lazy(() => import("./pages/assets/Assets")));
const CreateAsset = SpinningLoader(lazy(() => import("./drawers/assets/CreateAsset")));
const EditAsset = SpinningLoader(lazy(() => import("./drawers/assets/EditAsset")));
const DeleteAsset = SpinningLoader(lazy(() => import("./drawers/assets/DeleteAsset")));

const DeleteAssetAbnormalThresholdDrawer = SpinningLoader(
  lazy(() => import("./drawers/assets/DeleteAssetAbnormalThresholdDrawer"))
);

const Plants = SpinningLoader(lazy(() => import("./pages/plants/Plants")));
const CreatePlant = SpinningLoader(lazy(() => import("./drawers/plants/CreatePlant")));
const EditPlant = SpinningLoader(lazy(() => import("./drawers/plants/EditPlant")));
const DeletePlant = SpinningLoader(lazy(() => import("./drawers/plants/DeletePlant")));

const Personas = SpinningLoader(lazy(() => import("./pages/personas/Personas")));
const CreatePersona = SpinningLoader(lazy(() => import("./drawers/personas/CreatePersona")));
const EditPersona = SpinningLoader(lazy(() => import("./drawers/personas/EditPersona")));
const DeletePersona = SpinningLoader(lazy(() => import("./drawers/personas/DeletePersona")));

const DataSources = SpinningLoader(lazy(() => import("./pages/data-sources/DataSources")));
const CreateDataSource = SpinningLoader(
  lazy(() => import("./drawers/data-sources/CreateDataSource"))
);
const DeleteDataSource = SpinningLoader(
  lazy(() => import("./drawers/data-sources/DeleteDataSource"))
);
const EditDataSource = SpinningLoader(lazy(() => import("./drawers/data-sources/EditDataSource")));

const AssetTypes = SpinningLoader(lazy(() => import("./pages/assets/AssetTypes")));
const AddEditAssetType = SpinningLoader(
  lazy(() => import("./drawers/asset-types/AddEditAssetType"))
);
const DeleteAssetType = SpinningLoader(lazy(() => import("./drawers/asset-types/DeleteAssetType")));
const AssetSubTypes = SpinningLoader(lazy(() => import("./pages/assets/AssetSubTypes")));
const AddEditAssetSubType = SpinningLoader(
  lazy(() => import("./drawers/asset-sub-types/AddEditAssetSubType"))
);
const DeleteAssetSubType = SpinningLoader(
  lazy(() => import("./drawers/asset-sub-types/DeleteAssetSubType"))
);

const NameplateLabels = SpinningLoader(
  lazy(() => import("./pages/nameplate-labels/NameplateLabels"))
);

const CreateNameplateLabel = SpinningLoader(
  lazy(() => import("./drawers/nameplate-labels/CreateNameplateLabel"))
);
const EditNameplateLabel = SpinningLoader(
  lazy(() => import("./drawers/nameplate-labels/EditNameplateLabel"))
);
const DeleteNameplateLabel = SpinningLoader(
  lazy(() => import("./drawers/nameplate-labels/DeleteNameplateLabel"))
);

const ProductionUnits = SpinningLoader(
  lazy(() => import("./pages/production-units/ProductionUnits"))
);

const CreateProductionUnit = SpinningLoader(
  lazy(() => import("./drawers/production-units/CreateProductionUnit"))
);
const EditProductionUnit = SpinningLoader(
  lazy(() => import("./drawers/production-units/EditProductionUnit"))
);
const DeleteProductionUnit = SpinningLoader(
  lazy(() => import("./drawers/production-units/DeleteProductionUnit"))
);

const SubAreas = SpinningLoader(lazy(() => import("./pages/sub-areas/SubAreas")));
const CreateSubArea = SpinningLoader(lazy(() => import("./drawers/sub-areas/CreateSubArea")));
const EditSubArea = SpinningLoader(lazy(() => import("./drawers/sub-areas/EditSubArea")));
const DeleteSubArea = SpinningLoader(lazy(() => import("./drawers/sub-areas/DeleteSubArea")));

const LazySteadyStateDataGrid = SpinningLoader(
  lazy(() => import("./modules/steady-state/SteadyStateDataGrid"))
);

const Tags = SpinningLoader(lazy(() => import("./pages/tags/Tags")));
const CreateTag = SpinningLoader(lazy(() => import("./drawers/tags/CreateTag")));
const EditTag = SpinningLoader(lazy(() => import("./drawers/tags/EditTag")));
const DeleteTag = SpinningLoader(lazy(() => import("./drawers/tags/DeleteTag")));

const TagNotifications = SpinningLoader(
  lazy(() => import("./pages/tag-notifications/TagNotifications"))
);

const TagMapping = SpinningLoader(lazy(() => import("./pages/tag-mapping/TagMapping")));
const AssetPropertyNotes = SpinningLoader(
  lazy(() => import("./pages/asset-property-notes/AssetPropertyNotes"))
);
const DeleteAssetPropertyNote = SpinningLoader(
  lazy(() => import("./drawers/asset-property-notes/DeleteAssetPropertyNote"))
);
const CreateAssetPropertyNote = SpinningLoader(
  lazy(() => import("./drawers/asset-property-notes/CreateAssetPropertyNote"))
);

const AssetNotes = SpinningLoader(lazy(() => import("./pages/asset-notes/AssetNotes")));
const DeleteAssetNote = SpinningLoader(lazy(() => import("./drawers/asset-notes/DeleteAssetNote")));
const CreateEditAssetNote = SpinningLoader(
  lazy(() => import("./drawers/asset-notes/CreateEditAssetNote"))
);

const TagWeights = SpinningLoader(lazy(() => import("./pages/tag-weights/TagWeights")));
const CreateEditTagWeights = SpinningLoader(
  lazy(() => import("./drawers/tag-weights/CreateEditTagWeights"))
);
const DeleteTagWeights = SpinningLoader(
  lazy(() => import("./drawers/tag-weights/DeleteTagWeights"))
);

const ViewAttachmentDialog = SpinningLoader(
  lazy(() => import("./components/asset-notes/ViewAttachmentDialog"))
);

const AlarmThreshold = SpinningLoader(lazy(() => import("./pages/alarm-threshold/AlarmThreshold")));
const CreateEditAlarmThreshold = SpinningLoader(
  lazy(() => import("./drawers/alarm-threshold/CreateEditAlarmThreshold"))
);
const DeleteAlarmThreshold = SpinningLoader(
  lazy(() => import("./drawers/alarm-threshold/DeleteAlarmThreshold"))
);

type PermissionsWrapperConfigKeys =
  | "alarmModes"
  | "assetProperties"
  | "assetNameplateTemplates"
  | "assetTemplates"
  | "assetPersonaTemplates"
  | "assets"
  | "assetsAbnormalThresholds"
  | "assetTypes"
  | "assetSubTypes"
  | "dataSources"
  | "nameplateLabels"
  | "plants"
  | "personas"
  | "productionUnits"
  | "subAreas"
  | "steadyState"
  | "tags"
  | "tagMapping"
  | "tagNotifications"
  | "assetPropertyNotes"
  | "assetNotes"
  | "tagWeights"
  | "alarmThresholds"
  | "overview";

type PermissionsWrapperConfig = {
  title: string;
  Component: React.FC;
  crudPermissionIds?: CRUDPermissionIds;
};

type PermissionsWrapperConfigType = {
  [K in PermissionsWrapperConfigKeys]: PermissionsWrapperConfig;
};

const permissionsWrapperConfig: PermissionsWrapperConfigType = {
  alarmModes: {
    crudPermissionIds: AlarmsCRUDPermissionIds,
    Component: AlarmModes,
    title: "Assets"
  },
  assetProperties: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: AssetProperties,
    title: "Asset Properties"
  },
  assetNameplateTemplates: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: AssetNameplateTemplates,
    title: "Asset Nameplate Templates"
  },
  assetTemplates: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: AssetTemplatesPage,
    title: "Asset Templates"
  },
  assetPersonaTemplates: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: AssetPersonaTemplates,
    title: "Asset Templates (Alarms)"
  },
  assets: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: Assets,
    title: "Assets"
  },
  assetsAbnormalThresholds: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: AssetsAbnormalThresholds,
    title: "Asset Abnormal Thresholds"
  },
  assetTypes: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: AssetTypes,
    title: "Asset Types"
  },
  assetSubTypes: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: AssetSubTypes,
    title: "Asset Sub Types"
  },
  dataSources: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: DataSources,
    title: "DataSources"
  },
  nameplateLabels: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: NameplateLabels,
    title: "Nameplate Labels"
  },
  plants: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: Plants,
    title: "Plants"
  },
  productionUnits: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: ProductionUnits,
    title: "Production Units"
  },
  subAreas: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: SubAreas,
    title: "Sub Areas"
  },
  steadyState: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: LazySteadyStateDataGrid,
    title: "Steady State"
  },
  tags: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: Tags,
    title: "Tags"
  },
  tagMapping: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: TagMapping,
    title: "Tag Mapping"
  },
  tagNotifications: {
    crudPermissionIds: TagMappingsCRUDPermissionIds,
    Component: TagNotifications,
    title: "Tag Notifications"
  },
  assetPropertyNotes: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: AssetPropertyNotes,
    title: "Asset Property Notes"
  },
  assetNotes: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: AssetNotes,
    title: "Asset Notes"
  },
  overview: {
    Component: Overview,
    title: "Overview"
  },
  tagWeights: {
    Component: TagWeights,
    title: "Tag Weights & Subscriptions"
  },
  alarmThresholds: {
    Component: AlarmThreshold,
    title: "Alarm Threshold"
  },
  personas: {
    crudPermissionIds: AssetsCRUDPermissionIds,
    Component: Personas,
    title: "Personas"
  }
};

type RouteWithPermissionsProps = {
  id: PermissionsWrapperConfigKeys;
};

const WithPermissionsWrapper = (props: RouteWithPermissionsProps) => {
  const { Component, title } = permissionsWrapperConfig[props.id];
  return (
    <WithPermissions applicationId={config.applicationId} permissions={viewPermissions}>
      <>
        <Helmet>
          <title>{title ? title + " | Nutrien Operations" : "Nutrien Operations"}</title>
        </Helmet>
        <Component />
      </>
    </WithPermissions>
  );
};

const RouteWithPermissions = (props: RouteWithPermissionsProps) => {
  const { crudPermissionIds } = permissionsWrapperConfig[props.id];

  if (crudPermissionIds) {
    return (
      <PermissionsProvider
        applicationId={config.applicationId}
        crudPermissionIds={crudPermissionIds}
      >
        <WithPermissionsWrapper id={props.id} />
      </PermissionsProvider>
    );
  } else {
    return <WithPermissionsWrapper id={props.id} />;
  }
};

const AllRoutes = () => {
  const [hideLocationbar, setHideLocationbar] = useState(true);

  useEffect(() => {
    const handleLocationbar = () => {
      const hide = !window.location.pathname.includes("/accelerator/admin/auth/");
      if (hide) {
        !hideLocationbar && setHideLocationbar(true);
      } else {
        hideLocationbar && setHideLocationbar(false);
      }
    };

    window.addEventListener("popstate", handleLocationbar);

    return () => {
      window.removeEventListener("popstate", handleLocationbar);
    };
  }, [hideLocationbar]);
  return (
    <PageContainer title={""} hideLocationbar={hideLocationbar}>
      <Routes>
        <Route path="/alarm-modes" element={<RouteWithPermissions id="alarmModes" />}>
          <Route path="import-data" element={<CsvUpload type="alarmModes" />} />
          <Route path="create" element={<AddEditAlarmMode />} />
          <Route path=":alarmModeId/edit" element={<AddEditAlarmMode />} />
          <Route path=":alarmModeId/delete" element={<DeleteAlarmMode />} />
        </Route>
        <Route path="/asset-properties" element={<RouteWithPermissions id="assetProperties" />}>
          <Route path="import-data" element={<CsvUpload type="personaAssetProperties" />} />
          <Route path=":assetPropertyId/delete" element={<DeleteAssetProperty />} />
        </Route>
        <Route
          path="/asset-nameplate-templates"
          element={<RouteWithPermissions id="assetNameplateTemplates" />}
        >
          <Route path="import-data" element={<CsvUpload type="assetNameplateTemplates" />} />
          <Route
            path=":assetNameplateTemplateId/delete"
            element={<DeleteAssetNameplateTemplate />}
          />
        </Route>
        <Route path="/asset-templates" element={<RouteWithPermissions id="assetTemplates" />}>
          <Route path="import-data" element={<CsvUpload type="templates" />} />
          <Route path="clone" element={<CloneAssetTemplateDrawer />} />
          <Route path=":assetTemplateId/delete" element={<DeleteAssetTemplateDrawer />} />
        </Route>
        <Route
          path="/asset-persona-templates"
          element={<RouteWithPermissions id="assetPersonaTemplates" />}
        >
          <Route path="import-data" element={<CsvUpload type="personaTemplates" />} />
          <Route path="clone" element={<CloneAssetTemplateDrawer />} />

          <Route
            path=":assetPersonaTemplateId/delete"
            element={<DeleteAssetPersonaTemplateDrawer />}
          />
        </Route>
        <Route path="/assets" element={<RouteWithPermissions id="assets" />}>
          <Route path="import-data" element={<CsvUpload type="assets" />} />
          <Route path="create" element={<CreateAsset />} />
          <Route path=":assetId/view" element={<EditAsset isReadOnly />} />
          <Route path=":assetId/edit" element={<EditAsset />} />
          <Route path=":assetId/delete" element={<DeleteAsset />} />
        </Route>
        <Route
          path="/assets-abnormal-thresholds"
          element={<RouteWithPermissions id="assetsAbnormalThresholds" />}
        >
          <Route path="import-data" element={<CsvUpload type="assetAbnormalThresholds" />} />
          <Route
            path=":abnormalThresholdId/delete"
            element={<DeleteAssetAbnormalThresholdDrawer />}
          />
        </Route>
        <Route path="/asset-types" element={<RouteWithPermissions id="assetTypes" />}>
          <Route path="import-data" element={<CsvUpload type="assetTypes" />} />
          <Route path="create" element={<AddEditAssetType />} />
          <Route path=":assetTypeId/edit" element={<AddEditAssetType />} />
          <Route path=":assetTypeId/delete" element={<DeleteAssetType />} />
        </Route>
        <Route path="/asset-sub-types" element={<RouteWithPermissions id="assetSubTypes" />}>
          <Route path="import-data" element={<CsvUpload type="assetSubTypes" />} />
          <Route path="create" element={<AddEditAssetSubType />} />
          <Route path=":assetSubTypeId/edit" element={<AddEditAssetSubType />} />
          <Route path=":assetSubTypeId/delete" element={<DeleteAssetSubType />} />
        </Route>
        <Route path="/nameplate-labels" element={<RouteWithPermissions id="nameplateLabels" />}>
          <Route path="import-data" element={<CsvUpload type="assetNameplateLabels" />} />
          <Route path="create" element={<CreateNameplateLabel />} />
          <Route path=":nameplateLabelId/edit" element={<EditNameplateLabel />} />
          <Route path=":nameplateLabelId/delete" element={<DeleteNameplateLabel />} />
        </Route>
        <Route path="/plants" element={<RouteWithPermissions id="plants" />}>
          <Route path="import-data" element={<CsvUpload type="plants" />} />
          <Route path="create" element={<CreatePlant />} />
          <Route path=":plantId/edit" element={<EditPlant />} />
          <Route path=":plantId/delete" element={<DeletePlant />} />
        </Route>
        <Route path="/personas" element={<RouteWithPermissions id="personas" />}>
          <Route path="create" element={<CreatePersona />} />
          <Route path=":personaId/edit" element={<EditPersona />} />
          <Route path=":personaId/delete" element={<DeletePersona />} />
        </Route>
        <Route path="/production-units" element={<RouteWithPermissions id="productionUnits" />}>
          <Route path="import-data" element={<CsvUpload type="productionUnits" />} />
          <Route path="create" element={<CreateProductionUnit />} />
          <Route path=":productionUnitId/edit" element={<EditProductionUnit />} />
          <Route path=":productionUnitId/delete" element={<DeleteProductionUnit />} />
        </Route>
        <Route path="/data-sources" element={<RouteWithPermissions id="dataSources" />}>
          <Route path="create" element={<CreateDataSource />} />
          <Route path=":dataSourceId/edit" element={<EditDataSource />} />
          <Route path=":dataSourceId/delete" element={<DeleteDataSource />} />
        </Route>
        <Route path="/sub-areas" element={<RouteWithPermissions id="subAreas" />}>
          <Route path="import-data" element={<CsvUpload type="subAreas" />} />
          <Route path="create" element={<CreateSubArea />} />
          <Route path=":subAreaId/edit" element={<EditSubArea />} />
          <Route path=":subAreaId/delete" element={<DeleteSubArea />} />
        </Route>
        <Route path="/tags" element={<RouteWithPermissions id="tags" />}>
          <Route path="import-data" element={<CsvUpload type="tags" />} />
          <Route path="create" element={<CreateTag />} />
          <Route path=":tagId/edit" element={<EditTag />} />
          <Route path=":tagId/delete" element={<DeleteTag />} />
        </Route>
        <Route path="/tag-notifications" element={<RouteWithPermissions id="tagNotifications" />} />
        <Route path="/tag-mapping" element={<RouteWithPermissions id="tagMapping" />}>
          <Route path="import-data" element={<CsvUpload type="tagMappings" />} />
        </Route>
        <Route
          path="/asset-property-notes"
          element={<RouteWithPermissions id="assetPropertyNotes" />}
        >
          <Route path="import-data" element={<CsvUpload type="assetPropertyAnnotations" />} />
          <Route path="create" element={<CreateAssetPropertyNote />} />
          <Route path=":assetPropertyNoteId/delete" element={<DeleteAssetPropertyNote />} />
        </Route>
        <Route path="/asset-notes" element={<RouteWithPermissions id="assetNotes" />}>
          <Route path="create" element={<CreateEditAssetNote />} />
          <Route path=":assetNoteId/edit" element={<CreateEditAssetNote />} />
          <Route path=":assetNoteId/delete" element={<DeleteAssetNote />} />
          <Route path="download" element={<ViewAttachmentDialog />} />
        </Route>
        <Route path="/tag-weights" element={<RouteWithPermissions id="tagWeights" />}>
          <Route path="import-data" element={<CsvUpload type="tagWeights" />} />
          <Route path="create" element={<CreateEditTagWeights />} />
          <Route path=":tagWeightsId/edit" element={<CreateEditTagWeights />} />
          <Route path=":tagWeightsId/delete" element={<DeleteTagWeights />} />
        </Route>
        <Route path="/steady-state" element={<RouteWithPermissions id="steadyState" />}>
          <Route path=":steadyStateId/:action" element={null} />
          <Route path="*" element={null} />
        </Route>
        <Route path="/alarm-threshold" element={<RouteWithPermissions id="alarmThresholds" />}>
          <Route path="import-data" element={<CsvUpload type="alarmThresholds" />} />
          <Route path="create" element={<CreateEditAlarmThreshold />} />
          <Route path=":alarmThresholdId/edit" element={<CreateEditAlarmThreshold />} />
          <Route path=":alarmThresholdId/delete" element={<DeleteAlarmThreshold />} />
        </Route>

        <Route
          path="admin/*"
          element={
            <ApplicationAccessPermissionsProvider cruds={AcceleratorCRUDDefinitions}>
              <ApplicationAccessManagement
                hidePageContainer={true}
                basePath={basePath}
                applicationId={config.applicationId}
              />
            </ApplicationAccessPermissionsProvider>
          }
        />
        <Route path="/overview" element={<RouteWithPermissions id="overview" />} />
        <Route path="*" element={<Navigate to="/overview" replace />} />
      </Routes>
    </PageContainer>
  );
};

const Router = () => {
  return (
    <BrowserRouter basename={basePath}>
      <AllRoutes />
    </BrowserRouter>
  );
};

export default Router;
