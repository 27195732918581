export function elasticsearchEscape(s: string) {
  let result = "";
  for (let i = 0; i < s.length; i++) {
    const c = s.charAt(i);
    // These characters are part of the query syntax and must be escaped
    if (
      c === "\\" ||
      c === "+" ||
      c === "-" ||
      c === "!" ||
      c === "(" ||
      c === ")" ||
      c === ":" ||
      c === "^" ||
      c === "[" ||
      c === "]" ||
      c === '"' ||
      c === "{" ||
      c === "}" ||
      c === "~" ||
      c === "*" ||
      c === "?" ||
      c === "|" ||
      c === "&" ||
      c === "/"
    ) {
      result = result.concat("\\");
    } else if (c !== ">" && c !== "<") {
      // removed from string
      result = result.concat(c);
    }
  }
  return result.toString();
}
