import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, Persona, PersonaResponse, PersonaListResponse } from "../../../types";
import { personasQueryKey, personasListQueryKey } from "../query-cache";

export const useCreatePersona = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<PersonaResponse, HTTPError, Partial<Persona>> = {
    mutationFn: (persona) => AcceleratorApi.Personas.create(persona),
    onSuccess: (data: PersonaResponse) => {
      queryClient.setQueryData<PersonaListResponse | undefined>(
        personasListQueryKey(),
        (currentState: PersonaListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: [data.data, ...currentState.data]
          };
        }
      );
      queryClient.setQueryData(personasQueryKey(data.data.id), () => data);
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createPersona: mutateAsync,
    dataSource: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
