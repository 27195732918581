import {
  QueryClient as TanstackQueryClient,
  QueryClientProvider as TanstackReactQueryClientProvider
} from "@tanstack/react-query";
import { ReactNode } from "react";

/** For supporting `@packages/case-management-queries` */
const tanstackQueryClient = new TanstackQueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 0,
      refetchOnWindowFocus: false
    }
  }
});

/**
 * Abstraction point for all providers necessary to use queries or mutations
 * from any of the openapi autogenerated `@packages/*-queries` packages.
 *
 * @example
 * ```tsx
 * import { QueriesClientProvider } from "@packages/queries-client-auth";
 *
 * export default function App() {
 *   return (
 *     <QueriesClientProvider>
 *       <MyApp />
 *     </QueriesClientProvider>
 *   )
 * }
 */
export function QueriesClientProvider({ children }: { children: ReactNode }) {
  return (
    <TanstackReactQueryClientProvider client={tanstackQueryClient}>
      {children}
    </TanstackReactQueryClientProvider>
  );
}
