import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { Persona, PersonaResponse, PersonaListResponse, HTTPError } from "../../../types";

import { AcceleratorApi } from "../../..";
import { personasQueryKey, personasListQueryKey } from "../query-cache";

interface Variables {
  previousPersona: Persona;
  persona: Partial<Persona>;
}

export const useUpdatePersona = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<PersonaResponse, HTTPError, Variables> = {
    mutationFn: ({ previousPersona, persona }: Variables) =>
      AcceleratorApi.Personas.update(previousPersona, persona),
    onSuccess: (data: PersonaResponse) => {
      queryClient.setQueryData<PersonaListResponse | undefined>(
        personasListQueryKey(),
        (currentState: PersonaListResponse) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: currentState.data.map((state) => (state.id === data.data.id ? data.data : state))
          };
        }
      );
      queryClient.setQueryData(personasQueryKey(data.data.id), () => data);
    }
  };

  const { mutateAsync, data, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    persona: data?.data,
    isError,
    error,
    loading: isLoading,
    isSuccess,
    updatePersona: mutateAsync
  };
};
