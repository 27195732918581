import { Provider as JotaiProvider } from "jotai";
import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { QueriesClientProvider } from "@packages/queries-client-auth";

import {
  ThemeProvider,
  AppContainer,
  SnackbarProvider,
  ConfirmProvider
} from "@packages/theme-mui-v5";

import config from "./config";
import Router from "./Router";
import { ApiProvider } from "@packages/service-api";
import { initializeSentry } from "@packages/sentry";

initializeSentry(config.name);

export default function Accelerator() {
  return (
    <QueriesClientProvider>
      <JotaiProvider>
        <ApiProvider>
          <AppContainer appConfig={config}>
            <ThemeProvider provider={MUIThemeProvider}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider>
                  <ConfirmProvider>
                    <Router />
                  </ConfirmProvider>
                </SnackbarProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </AppContainer>
        </ApiProvider>
      </JotaiProvider>
    </QueriesClientProvider>
  );
}
