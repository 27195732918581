import { compare } from "fast-json-patch";
import { acceleratorApi } from ".";
import { PersonaListResponse, PersonaResponse, Persona } from "../../types/accelerator";
import { urlPathWithQueryParams } from "../../utils";

/**
 * Get Persona
 *
 * @return {Promise<PersonaListResponse>}
 */
export const getPersonas = async (): Promise<PersonaListResponse> => {
  return await acceleratorApi.get(urlPathWithQueryParams(`personas`, { page: 0, size: 1000 }));
};

/**
 * Get Persona By ID
 *
 * @param personaId: @ String
 *
 * @return {Promise<AssetResponse>}
 */
export const getPersonaById = async (personaId: string): Promise<PersonaResponse> => {
  return acceleratorApi.get(`personas/${personaId}`);
};

/**
 * Create Persona
 *
 * @param Persona: @ Partial<Persona>
 *
 * @return {Promise<PersonaResponse>}
 */
export const createPersona = async (persona: Partial<Persona>): Promise<PersonaResponse> => {
  return acceleratorApi.post("personas", persona);
};

/**
 * Patch Persona
 * @param previousValue: @type Persona
 * @param nextValue: @type Partial<Persona>
 *
 * @return {Promise<PersonaResponse>}
 */
export const patchPersona = (
  previousValue: Persona,
  nextValue: Partial<Persona>
): Promise<PersonaResponse> => {
  const patchUpdates = compare(previousValue, { ...previousValue, ...nextValue });
  return acceleratorApi.patch(`personas/${previousValue.id}`, patchUpdates);
};

/**
 * Delete Persona
 *
 * @param personaId: @ String
 *
 * @return {Promise<void>}
 */
export const deletePersona = async (personaId: string): Promise<void> => {
  await acceleratorApi.delete(`personas/${personaId}`);
};

export const PersonasApi = {
  getPersonas,
  getById: getPersonaById,
  create: createPersona,
  update: patchPersona,
  delete: deletePersona
};
