import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { PageContainer } from "@packages/theme-mui-v5";
import {
  CaseManagementAddPage,
  CaseManagementDataGrid,
  CaseManagementEditPage,
  CaseManagementHelpPage,
  CaseManagementRowAction
} from "@packages/case-management";
import CaseManagementConfig, {
  CaseManagementCRUDDefinitions,
  CaseManagementCaseCRUDPermissionIds,
  caseManagementApplicationId
} from "./config";
import ApplicationAccessManagement, {
  ApplicationAccessPermissionsProvider
} from "@packages/application-access-management";
import { PermissionsProvider, usePermissionContext } from "@packages/service-api";
import { useMemo } from "react";

function CaseManagementAddRoute() {
  const navigate = useNavigate();
  return <CaseManagementAddPage onAdded={() => navigate("..")} />;
}

function CaseManagementEditRoute() {
  const { caseId } = useParams<{ caseId: string }>();
  const navigate = useNavigate();
  return <CaseManagementEditPage caseId={caseId} onUpdated={() => navigate("..")} />;
}

function CaseManagementHelpRoute() {
  return <CaseManagementHelpPage />;
}

function CaseManagementSearchRoute() {
  const navigate = useNavigate();
  const permissions = usePermissionContext();

  const rowActions = useMemo(() => {
    if (permissions.canUpdate || permissions.canDelete) {
      return [
        {
          icon: "edit",
          color: "primary",
          tooltip: "Edit",
          action: (params) => navigate(`/${params?.data?.id as string}/edit`)
        }
      ] as CaseManagementRowAction[];
    }
  }, [permissions, navigate]);

  const defaultVisibleColumns = useMemo(() => {
    return [
      "caseId",
      "site",
      "asset",
      "description",
      "NROCFocusPerPlan",
      "alarmId",
      "alarmDate",
      "initiatingAction",
      "event",
      "impact",
      "severity",
      "type",
      "openedBy",
      "openedDate",
      "status"
    ];
  }, []);

  return (
    <CaseManagementDataGrid defaultVisibleColumns={defaultVisibleColumns} rowActions={rowActions} />
  );
}

const AllRoutes = () => {
  const { pathname } = useLocation();
  return (
    <PageContainer title={""} hideLocationbar={!pathname.includes("admin/auth/")}>
      <Routes>
        <Route path="/create" Component={CaseManagementAddRoute} />
        <Route path="/help" Component={CaseManagementHelpRoute} />
        <Route path="/:caseId/edit" Component={CaseManagementEditRoute} />
        <Route path="/" Component={CaseManagementSearchRoute} />
        <Route
          path="admin/*"
          element={
            <ApplicationAccessPermissionsProvider cruds={CaseManagementCRUDDefinitions}>
              <ApplicationAccessManagement
                hidePageContainer={true}
                basePath={CaseManagementConfig.path.value}
                applicationId={CaseManagementConfig.applicationId}
              />
            </ApplicationAccessPermissionsProvider>
          }
        />
      </Routes>
    </PageContainer>
  );
};

export default function CaseManagementSpaRouter() {
  return (
    <BrowserRouter basename={CaseManagementConfig.path.value}>
      <PermissionsProvider
        applicationId={caseManagementApplicationId}
        crudPermissionIds={CaseManagementCaseCRUDPermissionIds} // TODO hardcoded to `cases` entity for now
      >
        <AllRoutes />
      </PermissionsProvider>
    </BrowserRouter>
  );
}
