import * as React from "react";
import * as yup from "yup";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormGroup, Alert, Box } from "@mui/material";
import { TextField, Dialog, WithLoader } from "@packages/theme-mui-v5";

import { DialogActionButton } from "@packages/theme-mui-v5";
import { DataType, SelectedCells } from "../RenderSchema/GridView/manual-entry/ManualEntryRenderer";

export type Props = {
  onClose?: () => void;
  submit: (value: string) => void;
  loading: boolean;
  selectedCells: SelectedCells[];
  dataType: DataType;
};

type FormValues = {
  value: number | null | string;
};

const formSchema = yup.object().shape({
  value: yup.lazy((value) =>
    value === ""
      ? yup.string().required("Value is required.")
      : yup.number().required("Value is required.").typeError("Value must be a number")
  )
});

const formStringSchema = yup.object().shape({
  value: yup.string().required("Value is required.")
});

const EditBulkEntries = (props: Props) => {
  const { onClose, selectedCells, loading, submit, dataType } = props;

  const onSubmit = async ({ value }) => {
    submit(value);
  };

  const formResolverSchema = React.useMemo(
    () => (dataType === "number" ? formSchema : formStringSchema),
    [dataType]
  );

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      value: null
    },
    // typed as any as it's impossible to make these type coercion work between Yup, react-hook-form and our code.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: yupResolver(formResolverSchema as any) as unknown as Resolver<FormValues>
  });

  const [showAcknowledgement, setShowAcknowledgement] = React.useState<boolean>(false);

  const actions = React.useMemo(() => {
    if (showAcknowledgement) {
      return [
        {
          action: () => {
            handleSubmit(onSubmit)();
          },
          disabled: loading,
          text: `Confirm update ${selectedCells.length} values`,
          variant: "contained"
        },

        {
          action: () => {
            setShowAcknowledgement(false);
          },
          disabled: loading,
          text: "Cancel",
          color: "error",
          variant: "text"
        }
      ] as DialogActionButton[];
    }

    return [
      {
        action: () => {
          setShowAcknowledgement(true);
        },
        disabled: loading,
        text: `Update ${selectedCells.length} values`,
        variant: "contained"
      },
      {
        action: onClose,
        disabled: loading,
        text: "Cancel",
        variant: "outlined"
      }
    ] as DialogActionButton[];
  }, [showAcknowledgement, handleSubmit, loading, onClose, onSubmit, selectedCells]);

  return (
    <Dialog
      title={`Update ${selectedCells.length} entries`}
      onClose={onClose}
      disableBackdropClick
      isDraggable
      maxWidth={"sm"}
      actions={actions}
    >
      <WithLoader loading={loading}>
        <Alert sx={{ mb: 2 }} severity="warning">
          <Box sx={{ fontWeight: "bold" }}>Notes:</Box>
          <Box component="ul" sx={{ m: 1, pl: 2 }}>
            <Box component="li" sx={{ mb: 0.5 }}>
              This action will update all selected values in their source systems
            </Box>
          </Box>
        </Alert>
        <FormGroup onSubmit={handleSubmit(onSubmit)}>
          <TextField name="value" label="Value" control={control} required />
        </FormGroup>
      </WithLoader>
    </Dialog>
  );
};

EditBulkEntries.displayName = "EditBulkEntries";

export default EditBulkEntries;
