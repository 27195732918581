import { isEmpty } from "@packages/utils";
import { set, cloneDeep } from "lodash-es";

import { ColumnID, GroupID, RowID, SelectionMap, GroupedSelectionMap } from ".";
import { CellRange, Column, RangeSelectionChangedEvent } from "..";
import { AddOperation, ReplaceOperation, compare } from "fast-json-patch";

/**
 * Generic utility method for creating multi cell editing range selection handler.
 */
export function createRangeSelectionChangeHandler<TData>({
  columnGroupMapping,
  setRangeSelection
}: {
  columnGroupMapping: Record<ColumnID<TData>, GroupID>;
  setRangeSelection: (rangeSelection: GroupedSelectionMap<TData>) => void;
}) {
  return function onRangeSelectionChanged(event: RangeSelectionChangedEvent<TData>): void {
    const { api, finished } = event;

    const selection = {} as GroupedSelectionMap<TData>;

    if (finished) {
      (api.getCellRanges() ?? []).forEach((cellRange: CellRange) => {
        // Calculate starting and ending row. rowEnd can be lower than rowStart depending on user selection pattern
        const startRowIndex = Math.min(cellRange.startRow?.rowIndex, cellRange.endRow?.rowIndex);
        const endRowIndex = Math.max(cellRange.startRow?.rowIndex, cellRange.endRow?.rowIndex);

        for (let rowIndex = startRowIndex; rowIndex <= endRowIndex; rowIndex++) {
          // Retrieve ID. "getRowId" function should be overriden to provide TData type "ID" field value
          const rowId = api.getModel().getRow(rowIndex).id as RowID;

          cellRange.columns.forEach((column: Column<TData>) => {
            // Retrieve column name. Column value is not relevant here, as we wanna update all selected columns to the same value entred by user in a bulk change form.
            const columnId = column.getColId() as ColumnID<TData>;

            const groupId = columnGroupMapping[columnId];

            if (!isEmpty(groupId)) {
              if (isEmpty(selection?.[groupId]?.[rowId])) {
                if (isEmpty(selection[groupId])) {
                  selection[groupId] = {};
                }
                selection[groupId][rowId] = [columnId];
              } else if (!selection[groupId][rowId].includes(columnId)) {
                selection[groupId][rowId].push(columnId);
              }
            }
          });
        }
      });
    }

    setRangeSelection(selection);
  };
}

export type RangeSelectionConverterArgs<TData> = {
  data: Array<TData>;
  idKey: ColumnID<TData>; // Identifier key of passed generic type
  rangeSelection: GroupedSelectionMap<TData>; // Range selection map
  value: unknown; // User entered value
};

export type RangeSelectionConverterResult<TData> = Record<
  RowID,
  {
    previousValue: TData;
    nextValue: Partial<TData>;
  }
>;

/**
 * Generic utility method to convert range selection to array of specific entity/data type.
 */
export function mapRangeSelectionToEntityMap<TData>({
  data,
  idKey,
  rangeSelection,
  value
}: RangeSelectionConverterArgs<TData>): RangeSelectionConverterResult<TData> {
  const result = {} as RangeSelectionConverterResult<TData>;

  for (let index = 0; index < data?.length; index++) {
    const entity = data[index] as TData;

    // For each data entry. Check if range selection map contains any updated columns.
    const ID = entity[idKey] as RowID;

    Object.entries(rangeSelection).forEach(
      ([_groupId, selectionMap]: [GroupID, SelectionMap<TData>]) => {
        const entityUpdatedColumns = selectionMap[ID];

        if (!isEmpty(entityUpdatedColumns)) {
          // Generate update for currently entity
          result[ID] = {
            // Set original entity value
            previousValue: entity,
            // Set updated entity partial value (All updated columns will be set to the same entered value as part of a bulk change)
            nextValue: entityUpdatedColumns.reduce(
              (aggregate, column) => ({ ...aggregate, [column]: value }),
              {}
            )
          };
        }
      }
    );
  }

  return result;
}

type PatchOperation<T> = AddOperation<T> | ReplaceOperation<T>;

export type BatchUpsertSetRelatedFieldValuesOptions<T extends object> = {
  rootId: string;
  relatedId: string;
  relatedField: string;
  value: string | number | boolean;
  data: Array<T>;

  /**
   * A record of ids of items on the root object with dotpaths to the autopopulated field to update.
   *
   * @example
   * const rootFieldPaths = {
   *   "rootIdOne": ["personaTemplate.min", "personaTemplate.max"],
   *   "rootIdTwo": ["personaTemplate.min", "personaTemplate.max"]
   * };
   */
  rootFieldPaths: Record<string, Array<string>>;
};

export type BatchUpsertAutopopulatedFieldInstruction<T extends object> =
  | { action: "update"; id: string; payload: Array<PatchOperation<unknown>> }
  | { action: "create"; payload: object; root: T };

/**
 * This function will generate a set of instructions to either
 * create or update fields on an autopopulated / related field
 * which may or may not exist.
 *
 * If the autopopulated field exists, an instruction to `update` will
 * be returned with the `fast-json-patch` operations `payload` and `id`.
 *
 * If the autopopulated field does not exist, an instruction to
 * `create` the `payload` will be returned.
 *
 * See the test file for an example.
 */
export function batchUpsertSetAutopopulatedFieldValues<T extends object>(
  opts: BatchUpsertSetRelatedFieldValuesOptions<T>
): Array<BatchUpsertAutopopulatedFieldInstruction<T>> {
  const instructions: Array<BatchUpsertAutopopulatedFieldInstruction<T>> = [];

  Object.entries(opts.rootFieldPaths).forEach(([rootId, rootPaths]) => {
    // find the item by rootId
    const root = opts.data.find((item) => item[opts.rootId] === rootId);
    const prev = root?.[opts.relatedField];

    // prevId determines existing or not, since prev could be empty object (so that ag-grid can update values via dotpath)
    const prevId = prev?.[opts.relatedId];

    // create copy for next value
    const action = prevId ? "update" : "create";
    const next = action === "update" ? cloneDeep(prev) : {};

    // update values on the next object
    rootPaths.forEach((relatedFieldPath) => {
      /** The path of the field to set value within the relatedField prefix.  EG: "nested.min" becomes "min". */
      const fieldPath = relatedFieldPath.replace(new RegExp(`^${opts.relatedField}.`), "");
      set(next, fieldPath, opts.value);
    });

    // generate instructions depending on update / create
    if (action === "update") {
      const id = prev[opts.relatedId];
      const payload = compare(prev, next) as Array<PatchOperation<unknown>>;
      instructions.push({ action: "update", id, payload });
    } else {
      instructions.push({ action: "create", payload: next, root: root });
    }
  });

  return instructions;
}
