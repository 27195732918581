import { useQuery, UseQueryOptions } from "react-query";
import { AcceleratorApi } from "../../../apis";
import { AssetListResponse } from "../../../types";
import { assetSearchByIdQueryKey } from "../query-cache";

export function useSearchSearchAssetById(
  assetId: string,
  options: UseQueryOptions<AssetListResponse> = {}
) {
  const { isLoading, isSuccess, isError, data, error } = useQuery({
    queryKey: assetSearchByIdQueryKey(assetId),
    queryFn: () => AcceleratorApi.Assets.searchAssetById(assetId),
    enabled: !!assetId,
    refetchOnMount: "always",
    ...options
  });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    asset: data?.data ? data.data[0] : null,
    error
  };
}
